<template lang="pug">
div.container
  b-alert(show v-if="transactionError" variant="danger") Error processing payment! We have only ever seen this message on hacking attempts so something is definitely wrong. Please email members@igda.fi and we'll sort this out together. Details: <b>{{transactionError}}</b>
  div(v-if="processingPayment")
    loading-spinner(message="Processing payment...")
  div(v-if="!processingPayment")
    b-row(v-if="!member.membershipType")
      loading-spinner(message="Loading memberhip data...")
    div(v-if="member.membershipType")
      b-row.text-center(align-h="center")
        h1 Renew Your Membership
      b-row.text-center(align-h="center")
        p For information on the different memberships please see <a href="https://www.igda.fi/join-us/">igda.fi/join-us/</a>

      b-row(align-h="center").mt-5
        div.text-center
          b-button-group
            b-button(variant="primary" @click="newMembershipType = 'Individual'" :class="{'active': newMembershipType == 'Individual'}") Individual <br/> 40€
            b-button(variant="primary" @click="newMembershipType = 'Student'" :class="{'active': newMembershipType == 'Student'}") Student <br/> 25€
            b-button(variant="primary" @click="newMembershipType = 'Unemployed'" :class="{'active': newMembershipType == 'Unemployed'}") Unemployed <br/> 25€
            b-button(variant="primary" @click="newMembershipType = 'Lifetime'" :class="{'active': newMembershipType == 'Lifetime'}") Lifetime <br/> 800€

      b-row(align-h="center" align-v="center").mt-3
        div.col-md-5.col-lg-4.p-2
          b-list-group
            b-list-group-item(active) Current Membership
            //- b-list-group-item.d-flex.justify-content-between.align-items-center ID <span>{{member.igdaFinlandId}}</span>
            b-list-group-item.d-flex.justify-content-between.align-items-center Type <span>{{member.membershipType}}</span>
            b-list-group-item.d-flex.justify-content-between.align-items-center Status <span>{{membershipStatus}}</span>
            b-list-group-item.d-flex.justify-content-between.align-items-center(v-if="membershipStatus == 'VALID'") Expires <span>{{member.validUntil | moment("MMMM YYYY")}}</span>
            b-list-group-item.d-flex.justify-content-between.align-items-center(v-else) Expired <span>{{member.validUntil | moment("MMMM YYYY")}}</span>
        div.col-md-1.p-2.text-center.d-none.d-md-block.text-info
          fa-icon(icon="chevron-circle-right" size="3x")
        div.col-md-1.p-2.text-center.d-sm-block.d-md-none.text-info
          fa-icon(icon="chevron-circle-down" size="3x")
        div.col-md-5.col-lg-4.p-2
          b-list-group
            b-list-group-item(active) New Membership
            //- b-list-group-item.d-flex.justify-content-between.align-items-center ID <span>{{member.igdaFinlandId}}</span>
            b-list-group-item.d-flex.justify-content-between.align-items-center Type <span>{{newMembershipType}}</span>
            b-list-group-item.d-flex.justify-content-between.align-items-center Status <span>VALID</span>
            b-list-group-item.d-flex.justify-content-between.align-items-center(v-if="newMembershipType != 'Lifetime'") Expires <span>{{newValidUntil | moment("MMMM YYYY")}}</span>
            b-list-group-item.d-flex.justify-content-between.align-items-center(v-else) Expires <span>Never!</span>

      b-row(align-h="center" v-if="membershipStatus != 'EXPIRED'").mt-3
        p.text-center Note: we will <i>extend</i> your current membership. No reason to wait till the last minute before renewing!

      b-row(align-h="center").text-center.mt-5
        h4 {{newMembershipType}} Membership / {{amount}}€
      b-row(align-h="center").text-center
        h5(v-show="newMembershipType != 'Lifetime'") Valid until end of {{newValidUntil | moment("MMMM YYYY")}}
        h5(v-show="newMembershipType == 'Lifetime'") Valid until... forever!
      br/
      b-row(align-h="center")
        p(v-if="!stripe") Loading payment processor...
        b-button#checkout-button(v-else size="lg" variant="success" @click="openStripe") Go to Payment
</template>

<script>
import globalState from '@/main.js'

export default {
  data: function () {
    return {
      member: {
        membershipType: null,
        firstName: '',
        lastName: '',
        email: '',
        postOffice: ''
      },
      newMembershipType: null,
      processingPayment: false,
      transactionError: null,
      globalState,
      now: new Date(),
      stripe: null
    }
  },
  computed: {
    membershipStatus: function () {
      if (this.member.membershipType === 'Lifetime') return 'LIFETIME'
      else if (this.member.validUntil > this.now) return 'VALID'
      else return 'EXPIRED'
    },
    newValidUntil: function () {
      let newDate = new Date(this.member.validUntil)
      if (newDate < this.now) {
        newDate = this.now
        newDate.setMonth(newDate.getMonth())
      }
      newDate.setFullYear(newDate.getFullYear() + 1, newDate.getMonth() + 2, 0)
      return newDate
    },
    stripeProduct: function () {
      if (this.newMembershipType === 'Individual') return this.globalState.isProductionEnv ? 'sku_FYlzwGToZzHlXe' : 'sku_FYm5sQjRUHfKmR'
      else if (this.newMembershipType === 'Unemployed') return this.globalState.isProductionEnv ? 'sku_FYm0CA92DNUEir' : 'sku_FYm6SJlfKpUC9p'
      else if (this.newMembershipType === 'Student') return this.globalState.isProductionEnv ? 'sku_FYm0FcWN8n4ytZ' : 'sku_FYm7o11Ps7O0ui'
      else return this.globalState.isProductionEnv ? 'sku_FYm1rtQM4h186P' : 'sku_FYm4Or2SMp1rED'
    },
    amount: function () {
      if (this.newMembershipType === 'Individual') return 40
      else if (this.newMembershipType === 'Unemployed') return 25
      else if (this.newMembershipType === 'Student') return 25
      else return 800
    }
  },
  methods: {
    openStripe: function () {
      const baseUrl = this.globalState.isProductionEnv ? 'https://emmi.igda.fi/' : 'http://localhost:8080/'

      this.stripe.redirectToCheckout({
        items: [{
          sku: this.stripeProduct,
          quantity: 1
        }],
        clientReferenceId: JSON.stringify({ id: this.$route.params.id, membershipType: this.newMembershipType }),
        customerEmail: this.member.email,
        successUrl: baseUrl + 'individual-members/' + this.$route.params.id + '/' + this.$route.params.token + '/success',
        cancelUrl: baseUrl + 'individual-members/' + this.$route.params.id + '/' + this.$route.params.token + '/cancel'
      })
    }
  },
  mounted: function () {
    this.axios.get('/api/individual-members/editWithToken/' + this.$route.params.id + '/' + this.$route.params.token)
      .then(res => {
        if (res.data.membershipType === 'Lifetime') { this.$router.push({ path: '/individual-members/' + this.$route.params.id + '/' + this.$route.params.token }) }

        res.data.validUntil = new Date(res.data.validUntil)
        res.data.createdDate = new Date(res.data.createdDate)
        this.newMembershipType = res.data.membershipType
        this.member = res.data
      })
      .catch(err => {
        this.$notify({
          group: 'error',
          type: 'error',
          title: 'Fetching member details failed',
          text: err.hasOwnProperty('response') ? err.response.data : err,
          duration: -1
        })
      })

    this.$loadScript('https://js.stripe.com/v3')
      .then(() => {
        // eslint-disable-next-line no-undef
        this.stripe = Stripe(globalState.isProductionEnv ? 'pk_live_v3hSZ3tcDlldY0UKoRGyIiSa' : 'pk_test_EczHd4sOEuvAGcM2Wn8Tn5Zc')
      })
  }
}
</script>
